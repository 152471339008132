import Typography from '#components/shared/ui/Typography';
import { useEffect, useMemo, useState, type FC } from 'react';
import styles from './MainFooterExpandableLegalInfo.module.scss';

type Props = {
  info: string;
};

const MainFooterExpandableLegalInfo: FC<Props> = ({ info }) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [isDesktop, setIsDesktop] = useState<boolean>(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)');
    if (mediaQuery.matches) {
      setIsDesktop(true);
    }
  }, []);

  const limitTextLengthByViewport = useMemo(
    () => (text: string) => {
      const limit = isDesktop ? 399 : 217;
      return text.substring(0, limit);
    },
    [isDesktop]
  );

  return (
    <>
      <Typography
        component="p"
        variant="capture"
        color="lightGrey"
        className={collapsed ? styles.collapsed : ''}
        onClick={() => setCollapsed((collapsed) => !collapsed)}
      >
        {collapsed && (
          <>
            {limitTextLengthByViewport(info)}... <span className={styles.more}>more</span>
          </>
        )}
        {!collapsed && <>{info}</>}
      </Typography>
    </>
  );
};

export default MainFooterExpandableLegalInfo;
